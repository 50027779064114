import React from 'react'
import Layout from '../../components/layout'
import { Grouping2Creator } from '../../components/ChallengeCreators'

const ChallengeCreatorPage = ({ challengeId }) => (
  <Layout>
    <Grouping2Creator challengeId={challengeId} />
  </Layout>
)

export default ChallengeCreatorPage
